import request from "@/utils/axios";
import { apiUrl } from "@/utils/global";
// 登录接口
export const accountLogin = (data: object) =>
  request({
    url: "/ua/login",
    method: "post",
    data
  });

// 字典接口
export const listMulti = (params: object) =>
  request({
    url: `${apiUrl.BasicInfoApi}/basicdictinfo/listMulti`,
    method: "get",
    params
  });

// 获取应用模块
export const findSysLinkByUserId = () =>
  request({
    url: `${apiUrl.SystemApi}/syslink/findSysLinkByUserId`,
    method: "post"
  });

// 获取用户信息
export const getUserByHeader = () =>
  request({
    url: `${apiUrl.SystemApi}/sysuser/getUserByHeader`,
    method: "post"
  });
// 获取用户信息
export const getRole = () =>
  request({
    url: `${apiUrl.SystemApi}/sysuserrole/findUserRoleIdListByHeader`,
    method: "post"
  });

// 获取授权信息
export const getServerInfo = () =>
  request({
    url: `${apiUrl.SystemApi}/server/info`,
    method: "get"
  });

// 根据用户获取用户常用应用列表
export const getUserAppData = () =>
  request({
    url: `${apiUrl.SystemApi}/syslink/findCommMenu`,
    method: "post"
  });

// 根据用户添加用户常用应用
export const addAppDataByUser = (data: object) =>
  request({
    url: `${apiUrl.SystemApi}/syslink/insertCommMenu`,
    method: "post",
    data
  });

// 根据用户更新用户常用应用
export const updateAppDataByUser = (data: object) =>
  request({
    url: `${apiUrl.SystemApi}/syslink/updateCommMenu`,
    method: "post",
    data
  });

// 根据用户删除用户常用应用
export const delAppDataByUser = (data: object) =>
  request({
    url: `${apiUrl.SystemApi}/syslink/deleteCommMenu`,
    method: "post",
    data
  });

// 根据用户获取入口应用模块
export const getAppData = () =>
  request({
    url: `${apiUrl.SystemApi}/syslink/findCateSysLinkByUserId`,
    method: "post"
  });

// 水印
export const findUserWatermarkByUserId = (params: object) =>
  request(
    {
      url: `${apiUrl.SystemApi}/sysUseridWatermark/findUserWatermarkByUserId`,
      method: "get",
      params
    },
    {
      type: "doLast"
    }
  );
